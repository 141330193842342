<template>
  <div class="cw-application cw-product-selection pt-6">
    <h1
      :class="`cw-hero__slogan__title text-center
        ${$vuetify.breakpoint.smAndUp ? 'text-h4' : 'text-h6'}`"
    >
      <translate>
        Welcome to the loan application
      </translate>
    </h1>
    <p class="my-3 text-center grey--text">
      <translate>
        Select the loan product you want
      </translate>
    </p>
    <cw-product-cards/>
  </div>
</template>

<script>
export default {
  name: 'CwProductSelection',

  components: {
    'cw-product-cards': () => import('@shared/components/ProductCards'),
  },
};
</script>
